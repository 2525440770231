.modal-header {
    .mita-header-pride {
        position: relative;

        &::after {
            background: linear-gradient(90deg, #e50000 0, #e68d00 20%, #e6d600 40%, #008100 60%, #004cff 80%, #760188 100%);
            height: .15rem;
            position: absolute;
            bottom: -.65rem;
            left: 0;
            right: 0;
            content: "";
        }
    }
}