.khaibaoyte {
    height: 100%;
    overflow: auto;
    background-color: #fff !important;
    padding-bottom: 20px;
    padding-top: 20px;
}

.bg-header {
    background-color: white;
    position: sticky;
    top: -1px;
    z-index: 9;
}

.text-header {
    color: #7C797A;
    padding-top: 15px;
    padding-bottom: 20px;
}

.khaibao-image {
    width: -webkit-fill-available;
    max-width: 700px;
    max-height: 145px;
}

.khaibao-container {
    padding: 0 10px;
    font-size: 14px !important;
    max-width: 700px;
    margin: 0 auto;
}

.info-header {
    align-self: flex-end;
}

.start-important {
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px !important;
}

.khaibao-qrcode {
    height: 42px !important;
    width: 42px !important;
    margin-top: 5px;
}

.khaibaoyte small {
    font-size: 13px !important;
}

.khaibaoyte .fontsize12 {
    font-size: 12px !important;
}

.submit-qrcode {
    height: 150px !important;
    width: 150px !important;

}
