dialog#notificationMessageModal, div.notificationMessageModal {
    padding: 1rem 3rem;
    border: 1em solid transparent;
    background: linear-gradient(white, white) padding-box,
        repeating-linear-gradient(-45deg,
            #f78484 0, #f78484 12.5%,
            transparent 0, transparent 25%,
            #58a 0, #58a 37.5%,
            transparent 0, transparent 50%) 0 / 5em 5em;
    width: 500px;
    padding-top: 2rem;
    border-radius: 20px;
    box-shadow: 0 5px 30px 0 rgb(0 0 0 / 10%);
    animation: fadeIn 1s ease both;

    &::backdrop {
        animation: fadeIn 1s ease both;
        background: rgb(255 255 255 / 40%);
        z-index: 2;
        backdrop-filter: blur(5px);
    }

    .x {
        filter: grayscale(1);
        border: none;
        background: none;
        position: absolute;
        top: 15px;
        right: 10px;
        transition: ease filter, transform 0.3s;
        cursor: pointer;
        transform-origin: center;

        &:hover {
            filter: grayscale(0);
            transform: scale(1.1);
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}