.loginwrapper {
    width: 390px;
    position: relative;

    border: none;
    border-radius: 15px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.card-body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 20;
    padding: 0 !important;

    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
    backdrop-filter: blur(7.5px);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.12)
}

.card-form {
    display: flex;
    flex-direction: column;
    padding: 40px !important;
}

.logo {
    margin: 10px 0px 80px  0px;
    align-self: center;
}

.prefixIcon {
    position: absolute;
    width: 42px;
    height: 42px;
    font-size: 16px !important;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(58, 84, 163,0.9);
    border-radius: 0.25rem 0 0 0.25rem;
}

.showPassword {
    right: 35px;
    padding: 14px;
    color: rgba(58, 84, 163,0.9);
    cursor: pointer;
}

.intputLoginCustom {
    width: 100%;
    height: 42px;
    background-color: rgba(255, 255, 255, 0.7) !important;
    color: #3f4042;

    outline: 0;
    border: 1px solid #ced4da !important;
    border-radius: .25rem;
    padding-left: 48px;

    font-weight: 400;
    font-size: 15px !important;
}

.intputLoginCustom:hover, .intputLoginCustom:focus {
    border-color: rgb(255,255,255,0) !important;
    border-radius: 5px;
    box-shadow: 0 0 0 0.1rem rgba(58, 84, 163,0.9) !important;
}

.intputError {
    box-shadow: 0 0 0 0.2rem rgba(255, 49, 12, 0.25) !important;
}

.action {
    display: flex;
    justify-content: space-between;
    color: #2f53d0 !important;
    font-size: 14px !important;
}

.action i:hover {
    cursor: pointer;
    text-decoration: underline;
}

.buttonlogincustom {
    margin-top: 50px;
    width: 100%;
    height: 50px;
    font-weight: bold;
    font-size: 15px !important;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: rgb(58, 84, 163);

    border: none;
    border-radius: 0 0 15px 15px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.43461134453781514) 52%, rgba(255, 255, 255, 1) 100%);
}

.buttonlogincustom:hover {
    cursor: pointer;
    color: #fff;
    background: #2f53d0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(47, 83, 208, 0.5718662464985995) 52%, rgba(47, 83, 208, 1) 100%);
}

@media screen and (max-width: 768px) {
    .loginwrapper {
        border: none !important;
        margin-bottom: 30px !important;
        box-shadow: none !important;
    }
    .card-body {
        box-shadow: none !important;
    }
}

.loginwrapper .r {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

.loginwrapper .r:first-child {
    top: -100px;
    right: -100px;
    background: -webkit-linear-gradient(225deg, #1de9b6, #1dc4e9);
    background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
    animation: floating 7s infinite;
}

.loginwrapper .r.s:nth-child(2) {
    top: 150px;
    right: -150px;
    background: #04a9f5;
    animation: floating 5s infinite;
}

.loginwrapper .r.s:nth-child(3) {
    left: -150px;
    bottom: 150px;
    background: #1de9b6;
    animation: floating 3s infinite;
}

.loginwrapper .r.s:nth-child(2) {
    top: 150px;
    right: -150px;
    background: #04a9f5;
}

.loginwrapper .r:nth-child(4) {
    left: -100px;
    bottom: -100px;
    background: -webkit-linear-gradient(225deg, #899fd4, #a389d4);
    background: linear-gradient(-135deg, #899fd4, #a389d4);
    animation: floating 9s infinite;
}

.loginwrapper .r.s {
    width: 20px;
    height: 20px;
}

@keyframes floating {
    from {
        transform: rotate(0deg) translate(-10px) rotate(0deg);
    }

    to {
        transform: rotate(360deg) translate(-10px) rotate(-360deg);
    }
}

.bg-login {
    width: 100vw;
    height: 100%;
    background-color: rgb(233, 233, 233);
    font-size: 13px;
}

.bg-login::before {
    content: '';
    position: absolute;
    background-color: white;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
}

.btn-login {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #F47F16;
}


.glow {

    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #a1a1a1, 0 0 40px #888084, 0 0 50px #525051, 0 0 60px #0e0d0d, 0 0 70px #2b2a2a;
    }

    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #777576, 0 0 40px #292426, 0 0 50px #727071, 0 0 60px #202020, 0 0 70px #9c9c9c, 0 0 80px #2e1f27;
    }
}