#dwv {
    height: 90%;
}

.button-row {
    text-align: center;
}

/* Layers */
.layerContainer {
    position: relative;
    padding: 0;
    margin: auto;
    text-align: center;
}

.layer {
    position: absolute;
    pointer-events: none;
}

.drawDiv {
    position: absolute;
    pointer-events: none;
}

/* drag&drop */
.dropBox {
    margin: auto;
    text-align: center;
    vertical-align: middle;
    width: 50%;
    height: 75%;
}

.dropBoxBorder {
    border: 5px dashed #e8eaf6;
    /*indigo:light*/
}

.dropBoxBorder.hover {
    border: 5px dashed #3f51b5;
    /*indigo*/
}

/* Info */
.infoLayer {
    position: absolute;
    pointer-events: none;
    z-index: 20;
    width: 100%;
    height: 100%;
}

.infoLayer ul {
    margin: 0;
    padding: 2px;
    list-style-type: none;
}

.infoLayer li {
    margin-top: 0;
}

.info {
    color: #cde;
    text-shadow: 1px 1px #000;
    font-size: 80%;
}

.infoc {
    color: #ff0;
    text-shadow: 1px 1px #000;
    font-size: 120%;
}

.infotl {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
}

.infotc {
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    text-align: center;
}

.infotr {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}

.infocl {
    position: absolute;
    bottom: 50%;
    left: 0;
    text-align: left;
}

.infocr {
    position: absolute;
    bottom: 50%;
    right: 2px;
    text-align: right;
}

.infobl {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
}

.infobc {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    text-align: center;
}

.infobr {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
}

.infoLayer canvas {
    margin: 0;
    padding: 2px;
}

.plot {
    position: absolute;
    width: 100px;
    height: 50px;
    bottom: 15px;
    right: 0;
}