.mita-screen-saver {
    transition: 2s;
    position: absolute;
    height: 90%;
    width: 100%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    background: linear-gradient(-45deg, #23a5d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 8s ease infinite;
    height: 100vh;

    .mita-intro {
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 20px;
        opacity: 0;
        animation: fade-in 2s ease-in forwards;

        span {
            font-size: 15px;
            margin-right: 10px;
        }

        img {
            width: 100px;
            animation: fade 2s ease-in-out infinite;
        }
    }

    .mita-glassmorphism {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // background: rgba(255, 255, 255, 0.35);
        // backdrop-filter: blur(10px);
        // -webkit-backdrop-filter: blur(10px);

        .logo {
            animation: spin 2s infinite ease;
            filter: brightness(100);
            padding: 15px;
            width: 150px;
            max-width: "80vw";
        }
    }
}

@keyframes spin {
    50% {
        transition: 2s;
        transform: rotateY(180deg);
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes fade-in {
    100% {
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        filter: brightness(1);
    }
    50% {
        filter: brightness(10);
    }
    100% {
        filter: brightness(1);
    }
}